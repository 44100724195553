<template>
  <div class="loca">
    <div>
    <div id="container"></div>
    <div class="info">
      <h4 id="status">状态{{ status }}</h4>
      <hr>
      <p id="result">{{ result }}</p>
      <hr>
      <p>由于众多浏览器已不再支持非安全域的定位请求，为保位成功率和精度，请升级您的站点到HTTPS。</p>
    </div>
  </div>

  </div>
</template>
<script>
import AMap from 'AMap'

export default{
  data(){
    return{

    }
  },
  created(){
      this.getLocation()
    },
  methods:{
    getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      alert("您的浏览器不支持地理定位。");
      // const map = new AMap.Map('container', {
      // resizeEnable: true
      // });
      AMap.plugin('AMap.Geolocation', () => {
        const geolocation = new AMap.Geolocation({
          enableHighAccuracy: true,
          timeout: 10000,
          buttonPosition: 'RB',
          buttonOffset: new AMap.Pixel(10, 20),
          zoomToAccuracy: true
        });
        geolocation.getCurrentPosition((status, result) => {
          if (status === 'complete') {
            this.onComplete(result);
          } else {
            this.onError(result);
          }
        });
      });
    }
   },
   showPosition(position) {    
     alert("纬度: " + position.coords.latitude,"经度: " + position.coords.longitude)
   },
   onComplete(data) {
      this.status = '定位成功';
      let str = [];
      str.push('定位结果：' + data.position);
      str.push('定位类别：' + data.location_type);
      if (data.accuracy) {
        str.push('精度：' + data.accuracy + ' 米');
      }
      str.push('是否经过偏移：' + (data.isConverted ? '是' : '否'));
      this.result = str.join('<br>');
    },
    onError(data) {
      this.status = '定位失败';
      this.result = '失败原因排查信息:' + data.message;
    }
   
  }
}
</script>